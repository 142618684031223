function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
export function avoidFocusOnClick() {
    requestAnimationFrame(function() {
        if (_instanceof(document.activeElement, HTMLElement)) {
            document.activeElement.blur();
        }
    });
}
