"use client";
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
import { useOperationExecutor } from "@custom/schema";
import useSwr, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
export function clear(operation, variables) {
    mutate([
        operation,
        variables
    ]);
}
export function useOperation(operation, variables) {
    var _variables_pathname;
    var executor = useOperationExecutor(operation, variables);
    // If the executor is a function, use SWR to manage it.
    var result = useSwr([
        operation,
        variables
    ], // If the executor is not a function, pass null to SWR,
    // so it does not try to fetch.
    _instanceof(executor, Function) ? function(arg) {
        return executor(arg[1]);
    } : null, (variables === null || variables === void 0 ? void 0 : (_variables_pathname = variables.pathname) === null || _variables_pathname === void 0 ? void 0 : _variables_pathname.indexOf("__preview")) > 0 ? {
        suspense: false,
        keepPreviousData: true
    } : {
        suspense: false
    });
    return _instanceof(executor, Function) ? result : {
        data: executor,
        error: undefined,
        isValidating: false,
        isLoading: false
    };
}
export function useMutation(operation) {
    // Mutations don't support variable matching, since it does not make sense.
    var executor = useOperationExecutor(operation);
    return useSWRMutation(operation, function(_, opts) {
        return _instanceof(executor, Function) ? executor(opts.arg) : executor;
    });
}
