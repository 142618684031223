export var Backend = {
    Decap: "decap",
    Drupal: "drupal"
};
export var BlockTeaserListLayout = {
    Carousel: "CAROUSEL",
    Grid: "GRID"
};
export var CtaIconPosition = {
    After: "AFTER",
    Before: "BEFORE"
};
export var CtaIconType = {
    Arrow: "ARROW",
    None: "NONE"
};
export var ImagePosition = {
    Left: "left",
    Right: "right"
};
export var InfoGridIcon = {
    Email: "EMAIL",
    LifeRing: "LIFE_RING",
    None: "NONE",
    Phone: "PHONE"
};
export var Locale = {
    De: "de",
    En: "en"
};
export var CreateSubmissionMutation = "CreateSubmissionMutation:b585180e5579cb960b45197ad15ac71e5dc70d92bc935df5e2c92574edcd8d37";
export var ContentHubQuery = "ContentHubQuery:65f99634ab99a9759e9acde3156594b637e0fb38ecd43fb8774b705e31d635f2";
export var FrameQuery = "FrameQuery:3cef9ebc78ccdded20c19b3dd46f1c734b2a77e99b4c93477a12616647a2ab0a";
export var HomePageQuery = "HomePageQuery:6c71af02956a427af570638b629185a4ac3bb807590e48a91d9577bbe845d4b8";
export var ListPagesQuery = "ListPagesQuery:1f7e06f1abff052f072012075876848f4df447641e84c1cb9ab5809ae923108a";
export var NotFoundPageQuery = "NotFoundPageQuery:5ced669b42090ea1058482a491c74416e647e746d2d66022afc3e1c19f78efdb";
export var PreviewDecapPageQuery = "PreviewDecapPageQuery:04a36a5ac125fe41ba51475429ae563ed7adc7fa1c42aeeb8a190c80039a4b50";
export var PreviewDrupalPageQuery = "PreviewDrupalPageQuery:c11744041ebabb80d04bb29825ffb25bb2c157e70aeb70ec245f3aac60ce8430";
export var TeaserListQuery = "TeaserListQuery:63badec129d1d216bc0b94299f67e1a1da410899f3b34d4f3c6a6a108c4ee522";
export var ViewPageQuery = "ViewPageQuery:7034f9ff6896d177f8bf612ba41c134ae3ac0662f2fd4193891bcad6d561fb9e";
